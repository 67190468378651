/* remove react trello add lane button */
section.sc-dAbbbq.sc-feUYzb.fcqMAt.hVaQCL {
  display: none;
}
button.irogMi {
  display: none;
}

#net2phone-dialer iframe {
  height:  590px!important;
  width: 375px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 6px;
}

